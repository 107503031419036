import axios from 'axios';
import { setCurrentUser } from './Utils';
import { API_URL } from '../constants/defaultValues';

const instance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
});

instance.interceptors.request.use(
  (config) => {
    const userTokenInfo = JSON.parse(
      sessionStorage.getItem('gogo_current_user'),
    );
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${userTokenInfo.tokenType}${userTokenInfo.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest._retry &&
      (error.response.status === 406 ||
        error.response.status === 403 ||
        error.response.status === 401)
    ) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      const userTokenInfo = JSON.parse(
        sessionStorage.getItem('gogo_current_user'),
      );
      const responseData = await axios.post(`${API_URL}/auth/refresh`, {
        refreshToken: `${userTokenInfo.refreshToken}`,
      });
      const item = {
        accessToken: responseData.data.accessToken,
        refreshToken: responseData.data.refreshToken,
        tokenType: responseData.data.tokenType,
        date: 'date',
        id: 1,
        img: '',
        role: 0,
        title: 'title',
      };
      setCurrentUser(item);

      instance.defaults.headers.Authorization = `${responseData.data.tokenType}${responseData.data.accessToken}`;

      return instance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default instance;
