// eslint-disable-next-line import/no-cycle
import { UserRole } from '../helpers/authHelper';

// export const API_DOMAIN = 'http://localhost:8080';
export const API_DOMAIN = 'https://api.dmchana.co.kr';
export const API_URL = `${API_DOMAIN}/api`;

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'ko';
export const localeOptions = [{ id: 'ko', name: '한국어', direction: 'ltr' }];
// export const localeOptions = [
//   { id: 'en', name: 'English - LTR', direction: 'ltr' },
//   { id: 'es', name: 'Español', direction: 'ltr' },
//   { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
//   { id: 'ko', name: '한국어', direction: 'ltr' },
// ];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

// export const firebaseConfig = {
//   apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
//   authDomain: 'gogo-react-login.firebaseapp.com',
//   databaseURL: 'https://gogo-react-login.firebaseio.com',
//   projectId: 'gogo-react-login',
//   storageBucket: 'gogo-react-login.appspot.com',
//   messagingSenderId: '216495999563',
// };

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 0,
  title: '',
  img: '/',
  date: '',
  role: UserRole.Editor,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
