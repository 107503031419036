import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';

import instance from '../../helpers/instance';
import { UserRole } from '../../helpers/authHelper';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

// const loginWithEmailPasswordAsync = async (email, password, device) => {
//   const user = await axios.post('http://localhost:8080/api/auth/login', {
//     email,
//     password,
//     deviceInfo: {
//       deviceId: '1111',
//       deviceType: device,
//       notificationToken: '2222',
//     },
//   });
//   return user;
// };

const loginWithEmailPasswordAsync = async (email, password, device) => {
  const user = await axios.post('https://api.dmchana.co.kr/api/auth/login', {
    email,
    password,
    deviceInfo: {
      deviceId: '1111',
      deviceType: device,
      notificationToken: '2222',
    },
  });
  return user;
};

const meAsync = async () => {
  const me = instance.get(`/user/me`);
  return me;
};

// const loginWithEmailPasswordAsync = async (email, password) =>
//   // eslint-disable-next-line no-return-await
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((user) => console.log(user))
//     .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password, device } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      email,
      password,
      device,
    );
    if (!loginUser.message) {
      const item = {
        accessToken: loginUser.data.accessToken,
        refreshToken: loginUser.data.refreshToken,
        tokenType: loginUser.data.tokenType,
        ...currentUser,
      };
      setCurrentUser(item);
      const me = yield call(meAsync);
      let isAdmin = false;
      if (me) {
        me.data.roles.map((item) => {
          if (item.role === 'ROLE_ADMIN') {
            isAdmin = true;
          }
        });
      }
      if (isAdmin) {
        const updateItem = {
          accessToken: loginUser.data.accessToken,
          refreshToken: loginUser.data.refreshToken,
          tokenType: loginUser.data.tokenType,
          id: 1,
          title: me.data.email,
          img: '',
          date: '',
          role: UserRole.Admin,
        };
        setCurrentUser(updateItem);
        yield put(loginUserSuccess(updateItem));
        history.push(adminRoot);
      } else {
        sessionStorage.removeItem('gogo_current_user');
        history.push('/user/login');
      }
    } else {
      yield put(loginUserError('계정을 확인하세요.'));
    }
  } catch (error) {
    yield put(loginUserError('계정을 확인하세요.'));
  }
}

// export function* watchRegisterUser() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(REGISTER_USER, registerWithEmailPassword);
// }

// const registerWithEmailPasswordAsync = async (email, password) =>
//   // eslint-disable-next-line no-return-await
//   await auth
//     .createUserWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);

// function* registerWithEmailPassword({ payload }) {
//   const { email, password } = payload.user;
//   const { history } = payload;
//   try {
//     const registerUser = yield call(
//       registerWithEmailPasswordAsync,
//       email,
//       password,
//     );
//     if (!registerUser.message) {
//       const item = { uid: registerUser.user.uid, ...currentUser };
//       setCurrentUser(item);
//       yield put(registerUserSuccess(item));
//       history.push(adminRoot);
//     } else {
//       yield put(registerUserError(registerUser.message));
//     }
//   } catch (error) {
//     yield put(registerUserError(error));
//   }
// }

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

// eslint-disable-next-line no-unused-vars
const logoutAsync = async (history) => {
  sessionStorage.removeItem('gogo_current_user');
  history.push(adminRoot);
  // eslint-disable-next-line no-restricted-globals
  location.href = adminRoot;
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

// export function* watchForgotPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }

// const forgotPasswordAsync = async (email) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .sendPasswordResetEmail(email)
//     .then((user) => user)
//     .catch((error) => error);
// };

// function* forgotPassword({ payload }) {
//   const { email } = payload.forgotUserMail;
//   try {
//     const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
//     if (!forgotPasswordStatus) {
//       yield put(forgotPasswordSuccess('success'));
//     } else {
//       yield put(forgotPasswordError(forgotPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(forgotPasswordError(error));
//   }
// }

// export function* watchResetPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(RESET_PASSWORD, resetPassword);
// }

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .confirmPasswordReset(resetPasswordCode, newPassword)
//     .then((user) => user)
//     .catch((error) => error);
// };

// function* resetPassword({ payload }) {
//   const { newPassword, resetPasswordCode } = payload;
//   try {
//     const resetPasswordStatus = yield call(
//       resetPasswordAsync,
//       resetPasswordCode,
//       newPassword,
//     );
//     if (!resetPasswordStatus) {
//       yield put(resetPasswordSuccess('success'));
//     } else {
//       yield put(resetPasswordError(resetPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(resetPasswordError(error));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    // fork(watchRegisterUser),
    // fork(watchForgotPassword),
    // fork(watchResetPassword),
  ]);
}
